import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['fakeInput', 'input', 'cancel']

  static values = {
    selectedAmount: Number,
    updatePath: String,
  }

  handleKeydown(e) {
    if (['+', '-', 'E', 'e'].includes(e.key)) {
      e.preventDefault()
    }

    if (e.key == 'Enter') {
      e.preventDefault()
    }
  }

  // * parse input value
  // * update values for both inputs
  // * show or hide cancel buttons
  handleBlur(e) {
    const parsedValue = parseFloat(e.target.value).toFixed(2)

    if (parsedValue > 0) {
      e.target.value = parsedValue
      this.inputTarget.value = parseInt((parsedValue * 100).toFixed(2))

      if (parsedValue != e.target.min) {
        this.cancelTarget.classList.remove('hidden')
      } else {
        this.cancelTarget.classList.add('hidden')
      }
    } else {
      this.reset()
    }
  }

  reset() {
    this.cancelTarget.classList.add('hidden')
    this.inputTarget.value = this.selectedAmountValue
    this.fakeInputTarget.value = (this.selectedAmountValue / 100).toFixed(2)
  }

  async updateAmount() {
    const response = await fetch(this.updatePathValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/vnd.turbo-stream.html',
      },
      body: JSON.stringify({ amount: this.inputTarget.value }),
    })

    if (response.ok) {
      response.text().then(Turbo.renderStreamMessage)
    }
  }
}
