import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['count', 'input']

  handleCreateFormSubmit(e) {
    if (e.detail.success) {
      this.increment()
    }
  }

  handleDeleteFormSubmit(e) {
    if (e.detail.success) {
      this.decrement()
    }
  }

  increment() {
    if (this.count) {
      this.countTarget.innerText = parseInt(this.count) + 1
    } else {
      this.countTarget.innerText = '1'
    }
  }

  decrement() {
    if (this.count > 1) {
      this.countTarget.innerText = parseInt(this.count) - 1
    } else {
      this.countTarget.innerText = ''
    }
  }

  focusCommentInput() {
    this.inputTarget.focus()
  }

  get count() {
    return this.countTarget.innerText
  }
}
