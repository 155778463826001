import { Controller } from '@hotwired/stimulus'
import { loadScript } from '@paypal/paypal-js'
import Rails from '@rails/ujs'
import * as Sentry from '@sentry/browser'

export default class extends Controller {
  static targets = ['form', 'submit', 'paypalButton', 'cardTokenInput']
  static values = {
    paypalSetupToken: String,
    paypalIdToken: String,
    paypalClientId: String,
  }

  async connect() {
    if (this.paypalSetupTokenValue && this.paypalIdTokenValue) {
      this.initializePaypal()
    }
  }

  async initializePaypal() {
    loadScript({ 'client-id': this.paypalClientIdValue }).then((paypal) => {
      paypal
        .Buttons({
          locale: 'en_US',
          style: {
            color: 'gold',
            size: 'large',
            label: 'pay',
            height: 25,
          },
          createVaultSetupToken: () => this.paypalSetupTokenValue,
          onApprove: ({ vaultSetupToken }) => {
            this.paypalButtonTarget.classList.add('disabled')
            this.handlePaymentMethod(vaultSetupToken)
          },
          onClick: () => {
            this.paypalButtonTarget.classList.add('disabled')
          },

          onCancel: () => {
            this.paypalButtonTarget.classList.remove('disabled')
          },

          onError: (error) => {
            this.paypalButtonTarget.classList.remove('disabled')
            Sentry.captureException(error)
          },
        })
        .render(this.paypalButtonTarget)
        .then(() => {
          this.paypalButtonTarget.classList.remove('disabled')
        })
    })
  }

  handlePaymentMethod(payment_method_id) {
    this.cardTokenInputTarget.value = payment_method_id
    Rails.fire(this.formTarget, 'submit')
  }
}
