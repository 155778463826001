import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles tooltip.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Values
 * -------
 *
 * theme - (optional) - small
 *
 * Usage
 * -------
 *
 * <div
 *   data-controller="tooltip"
 *   data-tippy-content="Tooltip content"
 *   data-tippy-placement="top"
 *   data-tippy-maxwidth="300"
 * ></div>
 */

export default class extends Controller {
  static values = { theme: String }

  async connect() {
    const { default: tippy } = await import('tippy.js')

    this.tippy = tippy(this.element, {
      allowHTML: true,
      arrow: false,
      theme: this.themeValue,
      offset: [0, 4],
    })
  }

  disconnect() {
    this.tippy?.destroy()
  }
}
